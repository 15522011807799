.container {
  display: grid;
  place-items: center;
  min-height: 100vh;
  background-color: yellow;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.formContainer {
  --auth-offset-height: 128px;
  --auth-padding: 24px;
  --auth-border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: var(--auth-offset-height);
  padding: var(--auth-padding);
  border: 1px solid #eee;
  border-radius: var(--auth-border-radius);
  background: white;
}
