/** Logo specified in the branding file. */
.logo {
  margin-bottom: 2rem;
  max-height: 7rem;
  object-fit: contain;
  max-width: 240px;

  &:hover {
    cursor: pointer;
  }
}

/** Attribution for XDI - "Powered by XDI". */
.attributionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  background: #f7fafc;
  padding: 24px;

  width: calc(100% + var(--auth-padding) * 2);
  margin-block-end: calc(var(--auth-padding) * -1);
  border-radius: 0 0 var(--auth-border-radius) var(--auth-border-radius);
}
