.container {
  height: 160px !important;
  width: 160px !important;
  margin-top: 8px;
  margin-bottom: 16px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #777;
}
