.form {
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  :global(.ant-form-item-label) {
    padding: 0;
    padding-bottom: 4px;
  }

  :global(.ant-input) {
    line-height: 1;
  }

  :global(.ant-form-item) {
    margin-bottom: 0 !important;
  }
}

.title {
  font-size: 2.2rem !important;
  margin-block: 0 !important;
  letter-spacing: -0.01em;
}

.prompt {
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin-block: 0.25rem 2rem !important;
  color: #888 !important;
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 16px;
  color: #777;
}

.logInButton {
  margin-left: "8px";
  padding: 0;
  height: auto;
}

.alert {
  margin-bottom: 16px;
  width: 100%;
}
